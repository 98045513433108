import React from "react";
import { cn, DataObserveKey, FocusableComponent, VisualComponent } from "@siteimprove/fancylib";
import { FormControl } from "../form/form";
import * as scss from "./text-area.scss";

type TextAreaProps = FormControl<string> & {
	/** Placeholder text displayed when input is empty */
	placeholder?: string;
	/** Can the input be changed */
	disabled?: boolean;
	/** Height of the text area, defined by how many rows of text it will fit (default = 5) */
	rows?: number;
	/** Disable resizing of text area (by default height can be resized by users) */
	notResizable?: boolean;
} & VisualComponent &
	DataObserveKey &
	FocusableComponent;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
	const { rows = 5, notResizable, className, value, onChange, invalid, ...rest } = props;

	return (
		<textarea
			ref={ref}
			onChange={({ target: { value } }) => onChange(value)}
			value={value}
			data-component="text-area"
			className={cn(
				scss.textArea,
				invalid && scss.invalid,
				notResizable && scss.noResize,
				className
			)}
			rows={rows}
			{...rest}
		/>
	);
});
