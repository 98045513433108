import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { TextArea } from "./text-area";

export const Meta: DocPageMeta = {
	category: "Forms and input", // Which category does the component belong to?,
	title: "Text area", // Should the name in the styleguide menu be different from the component name?,
	notepad: "https://hackmd.io/O9XmWKDISSy26Q_3csLYGQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Text area"
			subTitle="Enables users to input multiple lines of text. Ideal for free-form responses, comments, or descriptions."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={TextArea} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The default variant is a blank field ready for user input. It visually indicates that it
					is interactive and accepts text. You can wrap <Code>FormElementWrapper</Code> around the{" "}
					<Code>TextArea</Code> to provide labelling and supplemental information.
				</Paragraph>
				<Paragraph>
					<b>Use cases</b>:
				</Paragraph>
				<Ul
					items={[
						"Long-form text input (comments, descriptions, reviews).",
						"Open-ended questions in forms.",
						"Any situation where a single line of text is insufficient.",
					]}
				/>
				<Example fn={DefaultExample} />
				<Header.H3>Disabled</Header.H3>
				<Paragraph>
					A disabled variant is visually distinct, appearing grayed out. It cannot be interacted
					with and does not accept input.
				</Paragraph>
				<Paragraph>
					<b>Best practices</b>:
				</Paragraph>
				<Ul
					items={[
						"When the information is for display only.",
						"When certain conditions haven't been met (e.g., a form field becomes enabled after another is filled out).",
						"To prevent accidental changes to existing text.",
					]}
				/>
				<Example fn={DisabledExample} />
				<Header.H3>Invalid</Header.H3>
				<Paragraph>
					An invalid variant has a red border or visual cue to indicate an error (e.g., the text
					entered doesn't match the required format).
				</Paragraph>
				<Paragraph>
					<b>Best practices</b>:
				</Paragraph>
				<Ul
					items={[
						"When input validation fails.",
						"To clearly communicate errors to the user and help them fix the issue.",
					]}
				/>
				<Paragraph>
					<b>Error Handling</b>:
				</Paragraph>
				<Ul
					items={[
						"Provide clear and specific error messages immediately below the text area.",
						"Guide users on how to fix the issue",
						<>
							Use inline validation to give real-time feedback as users type. Wrap{" "}
							<Code>FormElementWrapper</Code> around the <Code>TextArea</Code> to provide labelling
							and supplemental information.{" "}
						</>,
					]}
				/>

				<Example fn={InvalidExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={TextArea}
					initialProps={({ setState }) => ({
						placeholder: "Placeholder text",
						value: "value",
						onChange: (v) => setState({ value: v }),
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>TextArea</Code> for
					</Paragraph>
					<Ul
						items={[
							"Long-form text entry (comments, descriptions, reviews).",
							"Open-ended questions.",
							"Any content exceeding a single line.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>TextArea</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Forms and input/Form">Form</GatsbyLink>: For
								collecting user feedback, descriptions, etc, (e.g Create survey).
							</>,
							"Data entry: When large amounts of text are needed, (e.g Bulk keyword import).",
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>TextArea</Code> to existing components for visual consistency.
							</>,
							<>
								Ensure adequate sizing for the expected input length. Consider dynamic height
								adjustments.
							</>,
							<>If there's a character limit, include a character counter.</>,

							<>
								<b>Optional vs. Mandatory</b>:
								<Ul
									items={[
										<>Clearly indicate required fields with "(required)" or an asterisk (*).</>,
										<>
											If most fields are optional, only mark required fields. If most are required,
											mark optional fields.
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Using for single-line input. Use{" "}
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Input Field
								</GatsbyLink>{" "}
								instead.
							</>,
							<>
								Users should select from a limited set of choices. Use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>,{" "}
								<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink> or{" "}
								<GatsbyLink to="/lab/components/Forms and input/Radios">Radios</GatsbyLink> instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Do not use placeholders as the sole source of essential instructions.",
							"Ensure ample color contrast for text, background, and error states.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use clear and concise labels above the text area.",
							"Use placeholder text sparingly, only as a hint or example.",
							"Write clear and concise error messages.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => {
	const [value, setValue] = useState("");
	return <TextArea placeholder="Placeholder text" value={value} onChange={setValue} />;
};

const DisabledExample = () => {
	const [value, setValue] = useState("");

	return <TextArea placeholder="Disabled" value={value} onChange={setValue} disabled />;
};

const InvalidExample = () => {
	const [value, setValue] = useState("");

	return <TextArea placeholder="This is invalid" value={value} onChange={setValue} invalid />;
};
